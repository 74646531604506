/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500, 600, 700');
/* ==========================================================================
      2. GENERAL
      ========================================================================== */

body {
  overflow: hidden !important;
  font-family: "Poppins", sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
}

p {
  font-size: 14px;
  line-height: 26px;
}

.p {
  padding: 20px;
}

._slider {
  margin-left: 150px !important;
  padding-right: 150px;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flex {
  margin-top: 250px;
  width: 100%;
  height: 100%;
}

.tabber {
  padding-left: 0px;
  margin-bottom: 50px;
}

ul.tabber li {
  padding-right: 20px;
  padding-left: 20px;
  display: inline !important;
}

li.react-tabs__tab--selected {
  border: none !important;
  color: #333;
  /* background-color: #333 !important; */
  border-radius: 20px;
}

li.react-tabs__tab {}

a:hover, a:focus {
  color: #254d30;
}

.content_image {
  margin-top: -50px;
}

#team {
  padding-top: 0px;
}

.center {
  padding: 40px;
  color: #333;
  margin: auto;
  text-align: center !important;
}

.center span {
  padding-top: 20px;
  font-size: 20px;
}

.center img {
  margin: 20px;
}

.note {
  color: #333;
  font-size: 15px;
  text-align: left;
}

.bannerH1 {
  font-size: 140px;
  font-weight: 700;
  letter-spacing: 1px;
}

.bannerH2 {
  font-size: 50px;
  font-weight: 500;
  margin-top: -5px;
  letter-spacing: 1px;
}

.bannerH3 {
  font-size: 120px;
  font-weight: 800;
  line-height: 150px;
}

.bannerButton:hover {
  color: #fff;
}

.bannerGradient:hover {
  color: #fff;
}

.navbar-default .navbar-toggle .icon-bar {
  background: #fff !important;
}

.navbar-fixed-top {
  background: #333 !important;
}

.bannerGradient {
  border: none;
  box-shadow: none;
  text-shadow: none;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#254d30+0,ceaf57+100 */
  background: #254d30;
  /* Old browsers */
  background: -moz-linear-gradient(left, #254d30 0%, #ceaf57 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #254d30 0%, #ceaf57 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #254d30 0%, #ceaf57 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#254d30', endColorstr='#ceaf57', GradientType=1);
  /* IE6-9 */
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
}

.section-header h1, .section-header h3 {
  color: #26836f;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.mr-nav {
  margin-right: 50px;
}

.m-auto {
  margin: auto !important;
}

.navbar-toggler {
  outline: none !important;
  border: none;
}

.section-header h2 {
  color: #26836f;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 1px;
}

ul .ulInline {
  display: inline;
}

ul li {
  padding: 10px 0
}

#footer ul li {
  list-style: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

a:not([href]):not([tabindex]) {
  color: #fff;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: #254d30;
}

@media (max-width: 1000px) {
  .nav-link {
    padding: 20px !important;
    font-size: 1em;
    border-bottom: solid 1px #ccc;
  }
  .nav-link:last-child {
    padding: 20px !important;
    border-bottom: none;
  }
  #responsive-navbar-nav {
    background-color: #fff;
    /* margin-top: 50px !important; */
  }
}

/* ==========================================================================
      2.1 Section Title
      ========================================================================== */

.section {
  padding: 50px 50px;
}

.sections {
  padding: 80px 0 0 0;
}

.section-header {
  color: #fff;
  margin-bottom: 80px;
  text-align: center;
}

.section-header .section-title {
  font-size: 42px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  position: relative;
}

.sub {
  margin-top: 30px;
}

.sub p {
  font-size: 18px !important;
  color: #333;
  font-weight: 500;
}

.lightText {
  color: #fff !important;
}

.section-header .section-title span {
  color: #254d30;
}

.section-header .section-subtitle {
  margin-top: 15px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.section-header .lines {
  margin: auto;
  width: 70px;
  position: relative;
  border-top: 2px solid #23836e;
  margin-top: 15px;
}

/* ==========================================================================
      2.2 Buttons
      ========================================================================== */

.btn {
  font-size: 14px;
  padding: 11px 40px;
  border-radius: 0px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
}

.btn:focus, .btn:active {
  box-shadow: none;
  outline: none;
  color: #fff;
}

.btn-common {
  border: 1px solid #254d30;
  background: #254d30;
  position: relative;
  color: #fff;
  z-index: 1;
  border-radius: 30px;
}

.btn-common:hover {
  color: #fff;
  background: preset-drack;
  border-color: preset-drack;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.btn-border {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
}

.btn-border:hover {
  border: 2px solid #fff;
  color: #fff;
  background-color: #254d30;
}

.btn-lg {
  padding: 14px 33px;
  text-transform: uppercase;
  font-size: 16px;
}

.btn-rm {
  padding: 7px 0px;
  color: #999;
  text-transform: capitalize;
}

.btn-rm i {
  vertical-align: middle;
}

button:focus {
  outline: none !important;
}

.clear {
  clear: both;
}

/* ==========================================================================
   2.4  Pre Loader
   ========================================================================== */

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  z-index: 9999999999;
}

.spinner {
  width: 40px;
  height: 40px;
  top: 45%;
  position: relative;
  margin: 0px auto;
}

.footerMenu {
  text-align: left;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: preset;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* ==========================================================================
    -2.5 Scroll To Up
     ========================================================================== */

.back-to-top {
  display: none;
  position: fixed;
  bottom: 18px;
  right: 15px;
}

.back-to-top i {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  background-color: preset;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

/* ==========================================================================
      3. Hero Area
      ========================================================================== */

#hero-area {
  background: url(/src/assets/earth.png) fixed no-repeat;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
}

#hero-area .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fff;
  ;
  opacity: 0.9;
}

.contents {
  padding: 200px 0 130px;
}

.contents h1 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 25px;
}

.contents p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
}

.contents .btn {
  margin: 25px 25px 0px 0px;
  text-transform: uppercase;
}

.banner_bottom_btn {
  margin-top: 40px;
}

.banner_bottom_btn i {
  color: #fff;
  font-size: 48px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.banner_bottom_btn i:hover {
  color: #254d30;
}

/* ==========================================================================
      4. Navbar Style
      ========================================================================== */

.navbar-nav .nav-link {
  color: #333 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.navbar-nav .nav-link .hover {
  color: #254d30 !important;
}

.navbar-nav .nav-link.active {
  color: #fff !important;
}

.indigo {
  background: transparent;
}

.video-modal .modal-content {
  background: transparent;
  box-shadow: none !important;
  border: none;
}

.slicknav_menu .slicknav_icon-bar {
  background: #254d30;
}

@media (min-width: 768px) {
  .sections .container {
    /* width: 1100px !important; */
  }
}

/* only small tablets */

@media (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 768px) {
  .sub p {
    font-size: 14px !important;
    color: #333;
    line-height: 10px;
    font-weight: 500;
  }
  .bannerH1 {
    font-size: 57px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .bannerH2 {
    font-size: 25px;
    font-weight: 500;
    color: '#333';
    margin-top: -5px;
    letter-spacing: 1px;
  }
  .bannerH3 {
    font-size: 50px;
    font-weight: 800;
    margin-top: -15px;
    letter-spacing: 1px;
    line-height: 55px;
  }
  .navbar-header {
    width: 100%;
  }
}